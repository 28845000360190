  .wrapperArea {
    background: #fff;
    border: 2px solid transparent;
    padding: 20px;
    -webkit-border-radius: 12px;
    border-radius: 12px;
    transition: all 0.5s;
  }
  .thankBox {
    color: #fff;
    border-radius: 8px;
    background: rgb(42,51,121);
    background: linear-gradient(0deg, rgba(42,51,121,1) 0%, rgba(85,175,77,1) 100%);
  }
  .wrapperArea:hover {
    border-color: var(--colorGreen);
  }
  .bgTestimonial{
    background: url('../../assets/img/testi.jpg') ;
    background-size: cover;

  }
  .practicalArea{
    background: url('../../assets/img/area_bg.jpg') ;
    background-size: cover;

  }
  .testimonialWrapper img {
    width: 50px;
    -webkit-border-radius: 100%;
    border-radius: 100%;
  }
  .halfBlue{
    color: #1B2B40BF;
  }
  .btnWhite {
    background: #fff !important;
    color: var(--colorBlue) !important;
  }
  .counterSection {
    padding: 80px 0;
  }
  .intro-content hr {
    background: linear-gradient(90deg, rgba(4,80,140,1) 52%, rgba(4,80,140,1) 52%, rgba(255,255,255,1) 100%, rgba(255,255,255,1) 100%);
    border: 0;
    position: relative;
    left: calc(-6.8rem - 15px);
    z-index: 9;
    height: 2.4px;
    opacity: 1;
}
.bgcolorblueGradinat {
  background-color: #303a7c;
  background-position: center;
}
@media only screen and (max-width:990px) and (min-width:768px) {
  .mt80Tab{
    margin-top: 80px;
  }
body .tabfs24{
  font-size: 24px;
}
.intro-content{
  margin-top: 15px;
}
.single_bg .contentArea h2 {
  padding: 0 20px;
}
}
@media only screen and (max-width:767px) {
  .single_bg .contentArea h2 {
    font-size: 20px;
    line-height: 27px;
    padding: 0 20px;
  }
  .wrapperArea img {
    width: 29px;
  }
  .mobFullWidth {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    text-align: center;
  }
  .homeBanner {
    height: 200px;
  }
  .homeBanner img {
    height: 100%;
    object-fit: cover;
  }
  .counterSection {
    padding: 40px 0;
  }
}


.swiper-button-prev::after{
  background: url('../img/arrow_left.svg') !important;
  background-repeat: no-repeat !important;
  content: "" !important;
  height: 30px;
width: 50px;
}
.swiper-button-next::after{
  background: url('../img/arrow_right.svg') !important;
  background-repeat: no-repeat !important;
  content: "" !important;
  height: 30px;
width: 50px;
}

.shadowContent {
  box-shadow: 0px 13px 22px 0px #00000040;
}
.bgLightBluenew{
  background-color: #F4FBFF;
}
.w-80{
  width: 85%;
}
.bglight {
  background: #f4f5f8;
}
.boxSub {
  background-color: #fff;
  margin: 0 auto;
  width: 26%;
  padding: 10px;
  position: relative;
  z-index: 33;
}
.bCenter {
  position: relative;
  top: 32px;
  z-index: 0;
}

@media only screen and (min-width:991px) {
  .shadowContent {
    position: relative;
    left: -100px;
  }
  .shadowContentRight {
    position: relative;
    right: -100px;
    box-shadow: 0px 13px 22px 0px #00000040;
  }
  
  
}
@media only screen and (max-width:768px) {
 
  .w-80 {
width: 100%;
}
.bgImage h2 {
  font-size: 30px;
  width: 80%;
  margin: 0 auto 16px;
}
.boxSub {
  width: 100%;
}
.minH {
  min-height: auto !important;
}
.mobilecontentstart {
  justify-content: start !important;
}
.noUl {
  list-style: none;
  padding-left: 0px;
  font-size: 12px;
}
}
.iconWrapper {
  border: 8px solid var(--colorBlue);
  height: 150px;
  width: 150px;
  text-align: center;
  margin: 0 auto;
  border-radius: 100%;
  position: relative;
  top: -70px;
  background: #fff;
}
.serList {
  min-height: 350px;
}
.serList:hover {
  background: var(--colorGreen);
}
.serList:hover p {
color: #fff;
}
body .bgWhite {
  background: #fff;
}
.serList:hover .iconWrapper {
  border-color: var(--colorGreen);
}
.iconWrapper img {
  width: 70px;
}
.grd{
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e5799+0,ffffff+50 */
background: transparent; /* Old browsers */
background: -moz-linear-gradient(to bottom, transparent 50%,rgb(255, 255, 255) 50%); /* FF3.6-15 */
background: -webkit-linear-gradient(to bottom, transparent 50%,rgb(255, 255, 255) 50%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, transparent 50%,rgb(255, 255, 255) 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

}
.posUp {
  bottom: 0;
  z-index: 3;
}

.blueGrd{
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e5799+0,2a3379+50 */
background: rgb(30,87,153); /* Old browsers */
background: -moz-linear-gradient(to bottom, transparent 10%,rgb(42, 51, 121) 90%); /* FF3.6-15 */
background: -webkit-linear-gradient(to bottom, transparent 10%,rgb(42, 51, 121) 90%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, transparent 10%,rgb(42, 51, 121) 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#2a3379',GradientType=0 ); /* IE6-9 */

}
.minH {
  min-height: 478px;
}
.hovers:hover {
  background-color: #ddd;
  box-shadow: 0px 13px 22px 0px #00000040;
}
.onHover{
  display: none;
}
.wrp {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 73px;
} 
.mainSerWrap .onHover {
  display: none !important;
}
.mainSerWrap:hover .onHover {
  background: rgba(85,175,77,.9) !important;
  display: block !important;
}
.mainSerWrap:hover .default {
  display: none !important;
}
.imgClient img{
  width: 70px;
}