
  body,html,li,a,p,div,.roboto{
    font-family: 'Roboto', sans-serif;
  }
  h1,h2,h3,h4,h5,h6,.barlow{
    font-family: 'Barlow', sans-serif;
  }
  .Inria{
    font-family: 'Inria Sans', sans-serif;
  }
/* .breadCrumb{

} */
.single_bg{
  background: url('./assets/img/single_bg.jpg');
}

.landingbanner {
  background-image: url('./assets/img/mainweb.png');
  background-position: center;
  background-size: cover;
}
.landingbannernew {
  background-image: url('./assets/img/secondlanding.png');
  background-position: center;
  background-size: cover;
}
.bgimagefirst{
  background-image: url('./assets/img/bgfirst.png');
  background-position: center;
  background-size: contain;
}

.bgbottomlanding {
  background-image: url('./assets/img/brownbh.png');
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}
.shadowContentnew{
  box-shadow: 0px 13px 22px 0px #00000040;
}
.bgColornew {
  background-color: #c6844a;
}
.colorGrey1{
  color: #474747;
}
.letterspace2 {
  letter-spacing: 3px;
}
.letterspace1{
  letter-spacing: 1px;
}
.bgcolorblue {
  background-color: #56DCC6;
}
.bgcolorpink {
  background-color: #FFA894;
}
.bgcolorgreen{
  background-color: #D4CA3C;
}
.bgcolorlightb{
  background-color: #50B4DE;
}
.bgcolorbluenew{
  background-color: #9898FF;
}
.boxpadding {
  padding: 85px 45px;
  height: 437px;
}


.bgcolorpinknew{
  background-color: #B681EC;
}
.bgcolorpinknewone{
  background-color: #F088A8;
}
.bgcolorpinknewpure{
  background-color: #47D0E2;
}
.ptland40 {
  padding-top: 23px;
}
.wcustom {
  width: 70px;
}



.card-container .card {
  position: relative;
  width: 100%;
  border-radius: 0px;
  background-color: transparent;
  border: none;
  transition: 0.3s linear;
}
.card-container .card a {
  /* padding: 30px; */
  /* width: 100%; */
  /* height: 400px; */
  /* border: 2px solid black; */
  /* background: #fff; */
  /* text-decoration: none; */
  /* color: black; */
  /* display: block; */
  transition: 0.3s linear;
}
.card-container .card a:hover {
  transition: 0.3s linear;
  margin-top: -10px;

}
.card-container .card a:hover .card--display {
  display: none;
}
.card-container .card a:hover .card--hover {
  display: block;
  transition: 0.3s linear;
}

.card-container .card a .card--hover {
  display: none;
}
.card-container .card a .card--hover p {
  font-weight: normal;
  line-height: 1.5;
  text-transform: uppercase;
  transition: 0.3s linear;
}
.lastweb .col-md-2 {
  border-right: 2px solid #4d4747;
}
.lastweb .col-md-2:nth-last-child(1) {
  border-right: 0px solid #4d4747;
}
.sublist {
  padding-left: 0px;
  text-align: left;
}
.sublist li{
  font-family: 'Inria Sans', sans-serif;
  text-transform: uppercase;
  font-size: 15px;
}
.arrowDown path {
  color: #fff !important;
  fill: #fff !important;
}

.text-justify {
  text-align: justify;
}

.listedu ul{
 list-style: none;
}
.listedu li{
  position: relative;
  list-style: none;
  margin-bottom: 8px;
}
.listedu::before {
  content: "";
  background-color: #2a3379;
  position: absolute;
  top: 13px;
  height: 74px;
  width: 3px;
  left: 12px;
}
.listedu li::before {
  content: "";
  background-color: white;
  position: absolute;
  top: 4px;
  height: 17px;
  width: 17px;
  left: -27px;
  border: 3px solid #2a3379;
  border-radius: 50%;
}
.ml-4 {
  margin-left: 80px;
}
.pteam {
  padding: 66px !important;
}
.bgteam {
  background-color: #2a3379;
  background-repeat: no-repeat;
  background-position: right;
  box-shadow: 0px 0px 15px 0px #2a337973;
}
.doctorpro {
  background-color: #fff;
  text-align: left;
  box-shadow: 0px 0px 13px 0px #00000061;
  border-radius: 8px;
  padding: 24px;
  position: relative;
  left: -79px;
}
.teaming {
  height: 461px !important;
  object-fit: cover;
}
@media only screen and (max-width: 767px) {
 .teaming {
    height: auto !important;
    object-fit: cover;
}
  .landingbanner {
    background-image: url('./assets/img/mobilebannerlanding.png');
    background-position: center;
    background-size: cover;
  }
  .bgteam {
    background-image: none !important;
  }
  .pteam {
    padding: 26px !important;
  }
  .ml-4 {
    margin-left: 0px;
  }
  .doctorpro {
    left: 0px;
  }
  .sublist li {
    font-family: 'Inria Sans', sans-serif;
    text-transform: uppercase;
    font-size: 12px;
  }
  .lastweb .col-md-2 {
    border-right: 0px solid #4d4747;
  }
  .card-container .card a .card--hover p {
    font-weight: normal;
    line-height: 1.5;
    text-transform: capitalize;
    transition: 0.3s linear;
    font-size: 11px;
  }
  .boxpadding {
    padding: 29px 22px;
    height: 335px;
    margin-bottom: 27px;
  }
  .boxpadding img {
    width: 47px;
  }
  .ptland40 {
    padding-top: 0px;
  }
  
}

.bgGradient{
background: #f1faf4; /* Old browsers */
background: -moz-linear-gradient(left,  #060606 0%, #f7f3f3 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  #f1faf4 0%,#f7f3f3 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  #f1faf4 0%,#f7f3f3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1faf4', endColorstr='#f7f3f3',GradientType=1 ); /* IE6-9 */

}
.featureimag {
  height: 331px;
  object-fit: cover;
}
.headingcolor h3,.headingcolor h2, .headingcolor h4, .headingcolor h1, .headingcolor h5, .headingcolor h6  {
  color: var(--colorBlue);
}
.headingcolor a{
  color: var(--colorBlue);
}

.teamImgWrp img {
  border: 4px solid #fff;
  border-radius: 16px;
}
.singleTeam {
  height: 300px;
}
.singleTeam > .container {
  margin-top: 150px;
}
a.line {
  text-decoration: underline !important;
}


.inputTheme {
  border: 1px solid #D6D6D6;
  border-radius: 0px;
  padding: 18px 17px;
  height: 50px;
  transition: all 0.3s ease-in;
  outline: none;
}
.inputTheme:focus {
border-color: #2C367A
}
.fileUploadTheme {
  border: 1px solid #1B2B4026;
  height: 48px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 18px;
}
.fileUploadTheme .chooseFile {
  background: #1B2B40BF;
  color: #fff;
  border-radius: 20px;
  padding: 3px 10px;
  margin-left: 12px;
  font-size: 14px;
}
.tabbStyle {
  border: 1px solid #1B2B4026;
  border-radius: 30px;
}

.tabbStyle button {
  background: transparent;
  border: none;
  padding: 12px 30px 12px 30px;
  border-radius: 30px;
  font-size: 15px;
}
.tabbStyle button.active {
  background: var(--colorGreen);
  color: #fff;
}
.wrapperContact {
  -webkit-box-shadow: 0px 10px 60px #B0BAC554;
  box-shadow: 0px 10px 60px #B0BAC554;
  padding: 40px;
  background: #fff;
}
.formSection {
  margin-top: -50px;
}
/*react select*/
.themeSelect__indicator-separator {
  display: none;
}
.themeSelect__control {
  border: 1px solid #1B2B4026;
  outline: none !important;
}
.themeSelect__option--is-selected {
  background: #000 !important;
}
.themeSelect__option:hover {
  background: var(--colorGreen) !important;
  color: #fff;
}
.themeSelect *,
.themeSelect {
  outline: none !important;
}
.themeSelect__input-container {
  margin-top: -6px !important;
}
.themeSelect__value-container {
  height: 45px;
}
.themeSelect__control--is-focused {
  border-color: #1B2B4026 !important;
  -webkit-box-shadow: 0 0 0 0 var(--colorBlack) !important;
  box-shadow: 0 0 0 0 var(--colorBlack) !important;
}
.select_border div.themeSelect .themeSelect__control {
  border-color: #e8e9e9;
}
.themeSelect__placeholder {
  margin-top: -5px;
}
/*react select*/
.bgBlue.btnTheme:hover {
  background: var(--colorGreen) !important;
}
.bgGreen.btnTheme:hover {
  background: var(--colorBlue) !important;
}
.bgcover{
  background-size: cover;
}
.intro-content-second hr {
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(4,80,140,1) 52%, rgba(4,80,140,1) 100%, rgba(255,255,255,1) 100%);
  border: 0;
  position: relative;
  right: calc(-5.2rem - 15px);
  z-index: 9;
  height: 2.4px;
  opacity: 1;
}

.teamWrapper img {
  -webkit-border-radius: 20px 20px 0 0;
  border-radius: 20px 20px 0 0;
}
.testimonialWrapper {
  min-height: 323px;
}

@media only screen and (max-width: 767px) {
  body .formSection{
    margin-top: 0px;
    padding-top: 30px;
  }
  .Mobileteamimage img {
    width: 50%;
    margin-bottom: 15px;
  }
  .singleTeam > .container {
    margin-top: 55px;
  }
  body .mobileList {
    display: block !important;
  }
  .mobileList li {
    margin-bottom: 15px;
  }
}

::-webkit-scrollbar {
  width: 10px;
}


::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}


::-webkit-scrollbar-thumb {
  background: var(--colorGreen);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--colorGreen);
}

.contentArea ul {
  margin-bottom: 0px;
}
.intro-content-second hr {
  right: calc(0rem - 15px);
}

/*slider slick*/
.parentSl {
  margin: 0 -15px;
}
.slick-slider .slick-prev {
  top: -40px;
  right: 56px;
  left: inherit;
}
.slick-slider .slick-next {
  top: -40px;
  right: 20px;
}
/*slider slick*/


.subc:nth-child(1) .countersub::after {
  content: "+";
  position: relative;
  left: 3px;
}
.subc:nth-child(2) .countersub::after {
  content: "+";
  position: relative;
  left: 3px;
}
.subc:nth-child(3) .countersub::after {
  content: "+";
  position: relative;
  left: 3px;
}
.subc:nth-child(4) .countersub::after {
  content: "K";
  position: relative;
  left: 3px;
}
a {
  color: #87a90c;
}
.validation {
  font-size: 12px;
  color: red;
  margin-top: 5px;
  margin-left: 2px;
}
.bDivi{
  border-bottom: 1px solid #323d4c;
}
.bgcover {
  background-size: cover !important;
  background-repeat: no-repeat !important;

}
.videoWrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.videoWrapper button {
  background: transparent;
  border: none;
  outline: none;
  position: absolute;
  width: 100%;
  height: 100%;
}
.pointer{
  cursor: pointer;
}
body{
  overflow-x: hidden;
}
.skeleton {
  background-color: #e2e5e7;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;
  -webkit-border-radius: 7px;
  border-radius: 7px;
}
@keyframes shine {
  to {
    background-position: right -40px top 0;
 }
}

.phd {
  top: -23px;
}