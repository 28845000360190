.coreValue {
    border-radius: 8px;
    padding: 29px 15px;
    border: 3px solid #87A80D;
    transition: all 0.5s;
    cursor: pointer;
    min-height: 186px;
  }

  .coreValue:hover {
    border: 3px solid var(--colorBlue);
    margin-top: -15px;
    box-shadow: 0px 4px 9px 1px #00000075;
  }
  .videoWrapper video {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  .border_bottom::before {
    content: "";
    position: absolute;
    bottom: -30px;
    background-color: transparent;
    width: 60px;
    height: 30px;
    border-top: solid 30px rgb(255, 255, 255);
    border-left: solid 30px transparent;
    border-right: solid 30px transparent;
  }
  .MinSliderH .slick-slide .shadow {
    height: 308px;
  }
  .imgClient img {
    border-radius: 50%;
    border: 5px solid #55af4d;
  }
  @media screen and (max-width: 1024px) {

  .videoWrapper video {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}