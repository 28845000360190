.featuredImg {
  height: 227px;
}
.featuredImg a,
.featuredImg img {
  display: block;
  height: 100%;
  object-fit: cover;
}
.blogWrapper {
    border: 1px solid #ddd;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -o-border-radius: 12px;
    border-radius: 12px;
  }

  .featuredImg img {
    -webkit-border-radius: 12px 12px 0 0;
    border-radius: 12px 12px 0 0;
  }
  .blogWrapper:hover {
    border-color: #2B3678;
  }
  .active > .page-link, .page-link.active {
    background: var(--colorGreen) !important;
    border-color: var(--colorGreen) !important;
  }
  .pagination {
    margin-bottom: 35px;
  }
  .insight {
    border: 1px solid #ddd;
  }
  .catUl {
    list-style-type: disclosure-closed;
    list-style-position: inside;
  }
  .catUl > li a:hover{
    color: var(--colorGreen);
  }
  .overlaypText {
    position: absolute;
    background: rgba(255,255,255,0.8);
    right: 4%;
    width: 50%;
  }
  .contentUnder a {
    color: var(--colorBlue);
  }