:root {
  --colorBlack: #000000;
  --colorWhite: #fff;
  --colorGrey: #F7F8F9;
  --colorLightBlue: #E3F3FD;
  --colorOrange: #ec7523;
  --colorRed: #ff5757;
  --colorGreen: #55AF4D;
  --colorYellow: yellow;
  --colorPara: #1B2B40BF;
  --colorBlue: #2A3379;
}

body .colorBlack {
  color: var(--colorBlack);
}
.bgLightBlue{
  background: var(--colorLightBlue);
}
.colorWhite {
  color: #fff;
}
.bgBlack {
  background: var(--colorBlack);
}
.bgBlue {
  background: var(--colorBlue) !important;
}
.bgGreen {
  background: var(--colorGreen) !important;
}
body .bgWhite {
  background: #fff;
}
.colorOrange {
  color: var(--colorOrange);
}
.colorPara {
  color: var(--colorPara);
}
.colorBlue {
  color: var(--colorBlue);
}
.colorGreen {
  color: var(--colorGreen);
}
.colorRed {
  color: var(--colorRed);
}
.bgLightOrange {
  background: var(--colorLightOrange);
}
.bgOrange{
  background: var(--colorOrange);
}
.colorGrey {
  color: var(--colorGrey);
}
.bgGrey{
  background: #F6F6F7;
}
body .btnBlack {
  background: var(--colorBlack);
  color: #fff;
}
.bgLightOrange {
  background: var(--colorLightOrange);
}
