div[aria-expanded="true"] .plusIcon {
    display: inline-block !important;
  }
  div[aria-expanded="false"] .minusIcon {
    display: inline-block !important;
  }
  .fs18{
    font-size: 18px;
  }
  .fw700{
    font-weight: 700;
  }
  .colorGrey{
    color: gray;
  }
  .fs16{
    font-size: 16px;
  }
  .iconFs {
    font-weight: 900;
    font-size: 34px;
  }
  .iconFs img {
    width: 20px;
  }
  .nonActiveState,.activeState {
    border: 1px solid #ddd;
    padding: 15px;
    -webkit-border-radius: 12px;
    border-radius: 12px;
  }
  .accordionStyle.activeState{
    border: 1px solid var(--colorGreen);
    padding: 0px;
    -webkit-border-radius: 12px;
    border-radius: 12px;
  }

  .activeState > .accordion__heading {
    padding: 15px 15px;
  }
  .activeState > .accordion__panel {
    /* border: 1px solid var(--colorGreen); */
    padding: 15px 15px;
  }
  @media only screen and (max-width: 767px) {
    body .accTitle {
      font-size: 15px;
    }
    .accordion__button .accImg {
  display: none;
    }
    .fw700{
      font-weight: 600;
    }
    .fs18 {
      font-size: 15px;
    }
  }